import React from "react";
import Menu from "../components/Menu";
import target from "../images/svgs/target.svg";
import contact from "../images/svgs/contact_us.svg";
import ActivityContent from "../components/ActivityContent";
import ContactCard from "../components/ContactCard";
import AnimationWrapper from "../components/AnimationWrapper";
import SEO from "../components/SEO";

export default function about() {
  return (
    <AnimationWrapper>
      <SEO />
      <Menu />
      <div className="flow about-main">
        <section id="about" className="flow">
          <h1>RÓLUNK</h1>
          <div className="img-helper-TBG">
            <img className="imageTBG" src={target} alt="Célunk"></img>
          </div>
          <div id="about-main-text">
            <p className="subp-animate">
              A Kitárul a Világ Alapítvány megalakulásakor célul tűzte ki&nbsp;
              <strong>
                a hátrányos helyzetben lévő emberek helyzetének a javítását
              </strong>
              , illetve elhelyezkedési nehézségekkel küzdő aktív munkavállalói
              korban lévők továbbtanulási és munkavállalási lehetőségeinek a
              bővítését, nyelvtanulás elősegítését.
            </p>
            <p className="subp-animate">
              Az alapítvány tevékenysége kiterjed a
              <strong> magyarországi nemzeti és etnikai kisebbségekkel</strong>,
              valamint a <strong>határon túli magyarsággal</strong> kapcsolatos
              tevékenységre.
            </p>
            <p className="subp-animate">
              Alapítványunk szem előtt tarja a társadalmi kirekesztettség
              áldozatainak, a segítséggel élők társadalmi integrációjának
              elősegítését, a hátrányos helyzetű emberekkel szembeni
              <strong>előítéletek csökkentését</strong>.
            </p>
            <p className="subp-animate">
              Munkánk során megéltük, hogy a fogyatékkal élő személyek általános
              egészségi állapotának és a fogyatékossága jellegének
              <strong>
                megfelelő egészségügyi, szociális ellátásokhoz való hozzájutás
              </strong>
              biztosítására, közösségi, kulturális és szabadidős programokban
              való egyenjogú részvétel elősegítésére igen nagy szükség
              mutatkozik.
            </p>
            <p className="subp-animate">
              Célunk, hogy <strong>a hozzánk forduló emberek</strong> saját
              életterüket kialakítva megtalálják helyüket társadalmukban és
              <strong>mindenki máshoz hasonló életet éljenek</strong>.
            </p>
            <p className="subp-animate">
              Az alapítvány <strong>pályázatok</strong> írásával,
              <strong>támogatók segítésével</strong> tudja a bevételét növelni.
            </p>
          </div>
        </section>

        <section id="activity" className="flow">
          <h1>TEVÉKENYSÉGEK</h1>
          <div className="activities">
            <div className="activity-section activities-first">
              <ActivityContent imgIndex={8} textIndex={0} />
            </div>
            <div className="activity-section on-scroll-1 activities-second">
              <ActivityContent imgIndex={4} textIndex={1} />
            </div>
            <div className="activity-section on-scroll-2 activities-first">
              <ActivityContent imgIndex={3} textIndex={2} />
            </div>
            <div className="activity-section activities-second">
              <ActivityContent imgIndex={5} textIndex={3} />
            </div>
          </div>
        </section>

        <section id="contact" className="flow">
          <div className="contact-content">
            <h1>KERESS MEG MINKET!</h1>
            <div className="content-inner flow">
              <div className="cards flow">
                <ContactCard imgIndex={6} name="Kiss Róza" />
                <ContactCard imgIndex={1} name="Hajdú Lajos" />
                <ContactCard imgIndex={2} name="Tóth-Csikja Kornélia" />
              </div>

              <div id="contact-info" className="flow">
                <p>
                  <strong>Levelezési cím:</strong> 4150 Püspökladány, Petőfi
                  Sándor utca 19.
                </p>
                <p>
                  <strong>Tóth-Csikja Kornélia</strong> - 06-20-449-9505
                </p>
                <p>
                  <strong> Kiss Róza </strong> - 06-20-449-5070
                </p>
                <p>
                  <strong>Hajdu Lajos</strong> - 06-20-449-6662
                </p>
                <p>
                  <strong>E-mail cím: </strong>
                  <span>kitarul.</span>
                  <span>alapitvany</span>
                  <span>@gmail.com</span>
                </p>
                <p>
                  <strong>Kereskedelmi és Hitelbank Zrt: </strong>
                  <span>10403459</span>
                  <span>-50526575</span>
                  <span>-69691000</span>
                </p>
                <p>
                  <strong>Adószámunk</strong>: 18545276-1-09
                </p>
              </div>
            </div>
          </div>

          <div className="img-helper-CBG">
            <img className="imageCBG" src={contact} alt="Kapcsolat" />
          </div>
        </section>
      </div>
    </AnimationWrapper>
  );
}
