import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

export const imgQuery = graphql`
  {
    allFile(filter: { relativeDirectory: { ne: "svgs" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default function ContactCard({ imgIndex, name }) {
  const imgData = useStaticQuery(imgQuery);


  return (
    <div className="contact-card">
      <div className="card-container">
        <Image
          className="contact-image"
          fluid={imgData.allFile.nodes[imgIndex].childImageSharp.fluid}
          alt={`${name}`}
        />
      </div>
      <h4>{name}</h4>
    </div>
  );
}
