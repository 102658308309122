import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const imgQuery = graphql`
  {
    allFile(filter: { relativeDirectory: { ne: "svgs" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default function ActivityContent({ imgIndex, textIndex }) {
  const imgData = useStaticQuery(imgQuery);

  const activityText = [
    `Az alapítvány önkéntesei utazásszervezésben, kiállítások
          megvalósításában is tevékenykednek.Városi rendezvényeken,
          vásárokon, falunapokon segítséget nyújtottunk a fogyatékos
          személyek által készített termékek - peddignádból kosarak,
          viaszból rózsagyertyák, valamint gyöngyökből és féldrágakövekből
          ékszerek - értékesítésében. Hangsúlyozott feladatunknak tekintjük
          a profilunkhoz kapcsolódó rendezvényeken, konferenciákon való
          aktív részvételt, melyek jelentősen hozzájárulnak az újabb
          lehetőségek megismeréséhez, tapasztalatcseréhez.`,
    `Az alapítvány feladatait 1 fő részmunkaidős alkalmazottal, és
          önkéntesek bevonásával valósította meg. Az 4 órában alkalmazott
          dolgozót adminisztrátor munkakörben foglalkoztatjuk. Feladata,
          hogy elvégezze az alapítvány működéséhez, és programjaihoz
          szükséges ügyintézési feladatokat, szervezési tevékenységeket,
          programokra való propagálást, háttér munkálatokat.`,
    `Az elmúlt évben 15 fő önkéntes segítette az alapítvány munkáját,
          melyet a jövőben is szeretnénk megtartani, hiszen betekintést
          kaptak a hátrányos helyzetű csoportok életébe, tevékenységébe,
          ünnep-és mindennapjaiba. Elfogadták a fogyatékos személyeket és
          bizalom alapú együttműködés alakult ki közöttük. Az országos
          fesztiválokon, a regionális találkozókon rendszeresen veszünk
          részt. Az önkéntesek a fesztiválon résztvevő csoportok
          irányításában, vendéglátásban, étkeztetésében segédkeznek.`,
    `Az alapítvány munkája során segíti a településen megrendezésre
          kerülő programok szervezését, lebonyolítását igen nagy
          összefogással. A hátrányos helyzetű csoportok társadalmi
          esélyegyenlőségének javításában is segítséget nyújtottunk, hiszen
          a sport és a kultúra eszközeivel társadalmi csoportok közötti
          különbségek hidalhatóak át. Feladatunk, hogy esélyt teremtsünk
          mindenki számára a közösségi életben való aktív részvételre.`,
  ];

  return (
    <>
      <Image
        className="img"
        fluid={imgData.allFile.nodes[imgIndex].childImageSharp.fluid}
        alt="Képeink"
      />
      <p>{activityText[textIndex]}</p>
    </>
  );
}
